import 'froala-editor/js/plugins/align.min';
import 'froala-editor/js/plugins/char_counter.min.js';
// import 'froala-editor/js/plugins/code_beautifier.min';
import 'froala-editor/js/plugins/code_view.min';
import 'froala-editor/js/plugins/colors.min';
import 'froala-editor/js/plugins/draggable.min';
import 'froala-editor/js/plugins/edit_in_popup.min';
import 'froala-editor/js/plugins/emoticons.min';
import 'froala-editor/js/plugins/entities.min';
import 'froala-editor/js/plugins/font_family.min';
import 'froala-editor/js/plugins/font_size.min';
import 'froala-editor/js/plugins/forms.min';
import 'froala-editor/js/plugins/fullscreen.min';
// import 'froala-editor/js/plugins/help.min';
import 'froala-editor/js/plugins/image.min';
import 'froala-editor/js/plugins/image_manager.min';
import 'froala-editor/js/plugins/inline_class.min';
import 'froala-editor/js/plugins/inline_style.min';
import 'froala-editor/js/plugins/line_height.min';
import 'froala-editor/js/plugins/link.min';
import 'froala-editor/js/plugins/lists.min';
import 'froala-editor/js/plugins/video.min';
import 'froala-editor/js/plugins/paragraph_format.min';
import 'froala-editor/js/plugins/paragraph_style.min';
import 'froala-editor/js/plugins/print.min';
import 'froala-editor/js/plugins/special_characters.min';
import 'froala-editor/js/plugins/table.min';
import 'froala-editor/js/plugins/quick_insert.min';
import 'froala-editor/js/plugins/quote.min';
import 'froala-editor/js/plugins/url.min';
import 'froala-editor/js/plugins/word_paste.min';
// third_party
import 'froala-editor/js/third_party/font_awesome.min';
import 'froala-editor/css/third_party/font_awesome.min.css';
// third_party end

export default {
  data() {
    return {
      froalaConfig: {
        key: 'iTB2xB6B1C1C1E2C1wycB-22D-17bpdvxD1uxueF5C4C3E3E2C2A5D6B3F3==',
        scrollableContainer: '#app',
        heightMin: 200,
        heightMax: 20000,
        imageUploadURL: '/v2/admin/common/file',
        videoUpload: false,
        attribution: false,
        toolbarStickyOffset: 60,
        fontFamilySelection: true,
        fontSizeSelection: true,
        paragraphFormatSelection: true,
        enter: 2, // br换行
        iframe: false,
        // initOnClick: true,
        toolbarButtons: [
          ['undo', 'redo'],
          ['fullscreen', 'selectAll', 'html'],
          ['bold', 'italic', 'underline', 'textColor', 'backgroundColor'],
          ['inlineClass', 'inlineStyle', 'paragraphStyle', 'clearFormatting'],
          ['strikeThrough', 'subscript', 'superscript'],
          ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify'],
          ['formatOL', 'formatUL', 'indent', 'outdent', 'lineHeight'],
          ['paragraphFormat', 'fontFamily', 'fontSize'],
          ['insertLink', 'insertImage', 'insertVideo', 'quote'],
          ['insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR', 'draggable'],
          ['print', 'getPDF', 'spellChecker']
        ],
        quickInsertButtons: ['ul', 'ol', 'hr', 'image', 'table'],
        events: {
          'image.uploaded': function (response) {
            const res = JSON.parse(response);
            this.image.insert(res.value.url, false, null, this.image.get(), response);
            return false;
          }
        }
      }
    };
  },
  created() {
    // const id = 'html2pdf';
    // if (document.getElementById(id)) {
    // } else {
    //   let js = document.createElement('script');
    //   js.id = id;
    //   js.src = '/html2pdf.bundle.min.js';
    //   js.onload = () => {
    //   };
    //   document.body.appendChild(js);
    // }
  }
};
